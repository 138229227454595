import React from 'react';
import MenuButton from '@fingo/lib/components/buttons/MenuButton';
import PropTypes from 'prop-types';
import UploadFileButton from '@fingo/lib/components/buttons/UploadFileButton';
import ClearListButton from '@fingo/lib/components/buttons/ClearListButton';
import useMovementAccountsOptions from '../../../../../hooks/useMovementAccountsOptions';

const DebitAccountFilter = ({ selectedOption, setSelectedOption, setFile, file }) => {
  const { options } = useMovementAccountsOptions();
  const rightOptions = options?.filter((option) => option.active === true) || [];
  return (
    <>
      <ClearListButton setList={setSelectedOption} />
      <UploadFileButton setFile={setFile} variant="contained" size="small">
        {file ? file.name : 'Excel'}
      </UploadFileButton>
      <MenuButton
        id="account-filter"
        options={rightOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        placeholder="Cuenta"
      />
    </>
  );
};

DebitAccountFilter.propTypes = {
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
};

DebitAccountFilter.defaultProps = {
  selectedOption: undefined,
  file: null,
};

export default DebitAccountFilter;
