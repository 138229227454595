import { formatMoney } from '@fingo/lib/helpers';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import useGetConciliationMovementByIds from '../../../../../hooks/useGetConciliationMovementById';

const SelectedAmount = ({ selectedMovementIds }) => {
  const selectedMovements = useGetConciliationMovementByIds(selectedMovementIds);
  const amount = selectedMovements.debitValue?.amount ? selectedMovements.debitValue
    : selectedMovements.creditValue;
  if (selectedMovements.loading) {
    return (
      <Skeleton
        variant="text"
        height={50}
        width={120}
        sx={{
          mr: 'auto',
          ml: 'auto',
        }}
      />
    );
  }
  return (
    <Typography variant="h2" color="primary" align="center">
      {formatMoney(amount, true)}
    </Typography>
  );
};

SelectedAmount.propTypes = {
  selectedMovementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default SelectedAmount;
