import React from 'react';
import MenuButton from '@fingo/lib/components/buttons/MenuButton';
import PropTypes from 'prop-types';
import UploadFileButton from '@fingo/lib/components/buttons/UploadFileButton';
import ClearListButton from '@fingo/lib/components/buttons/ClearListButton';
import useMovementAccountsOptions from '../../../../../hooks/useMovementAccountsOptions';
import UploadPayrollConciliationDialog from '../../movements/components/UploadPayrollConciliationDialog';
import UploadBankStatementsDialog from '../../movements/components/UploadBankStatementsDialog';

const CreditAccountFilter = ({ selectedOption, setSelectedOption, setFile, file }) => {
  const { options } = useMovementAccountsOptions();
  const leftOptions = options?.filter((option) => option.passive === true) || [];
  return (
    <>
      <UploadBankStatementsDialog />
      <UploadPayrollConciliationDialog />
      <ClearListButton setList={setSelectedOption} />
      <UploadFileButton setFile={setFile} variant="contained" size="small">
        {file ? file.name : 'Excel'}
      </UploadFileButton>
      <MenuButton
        id="account-filter"
        options={leftOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        placeholder="Cuenta"
      />
    </>
  );
};

CreditAccountFilter.propTypes = {
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
};

CreditAccountFilter.defaultProps = {
  selectedOption: undefined,
  file: null,
};

export default CreditAccountFilter;
