import MenuButton from '@fingo/lib/components/buttons/MenuButton';
import { GridEditDateCell } from '@fingo/lib/components/dataGridCells';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { MoneyInput } from '@fingo/lib/components/inputs';
import { formatDayMonthYear } from '@fingo/lib/helpers';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useConciliationMovementsColumns from '../../../../../columns/useConciliationMovementsColumns';
import useConciliate from '../../../../../hooks/useConciliate';
import useConciliationMovementTypes from '../../../../../hooks/useConciliationMovementTypes';
import useGetConciliationMovementByIds from '../../../../../hooks/useGetConciliationMovementById';
import SelectedAmount from './SelectedAmount';
import NonOperativeMovement from './NonOperativeMovement';

const ConciliationGrid = ({ rows, columnList, columns, loading }) => {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('description');
  return (
    <Grid
      item
      lg={6}
      xs={12}
      minHeight={300}
      sx={{
        maxHeight: {
          xl: 570,
          lg: 420,
          md: 350,
          xs: 250,
        },
      }}
    >
      <FingoDataGrid
        rows={rows}
        page={page}
        columns={[
          ...useConciliationMovementsColumns().filter(
            (column) => columnList.includes(column.id),
          ),
          ...columns,
        ]}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        loadingWithSkeleton={loading}
        serverFilters={false}
        scrollbarSize={0}
        maxHeight="100%"
        onPageChange={setPage}
        selectionModel={rows.map((row) => row.id)}
      />
    </Grid>
  );
};

const ConfirmConciliation = ({
  open,
  close,
  debitMovementIds,
  creditMovementIds,
  clearSelection,
  setLeftSideSelectedId,
  setRightSideSelectedId,
}) => {
  const [selectedMovementType, setSelectedMovementType] = useState('');
  const [conciliationDescription, _setConciliationDescription] = useState('');
  const setConciliationDescription = useCallback(({ target: { value } }) => {
    _setConciliationDescription(value);
  }, []);
  const movementTypeOptions = useConciliationMovementTypes();
  const selectedMovementTypeCode = movementTypeOptions.find(
    (type) => type.id === selectedMovementType,
  );

  const [movementDateInput, _setMovementInput] = useState({});
  const [movementUnpayedAmountInput, _setMovementUnpayedAmountInput] = useState({});
  const [movementCondonedAmountInput, _setMovementCondonedAmountInput] = useState({});
  const setMovementInput = useCallback((changedRow, newValue) => {
    _setMovementInput((previous) => ({
      ...previous,
      [changedRow.id]: newValue,
    }));
  }, []);
  const setMovementUnpayedAmountInput = useCallback((id) => (value) => {
    _setMovementUnpayedAmountInput((previous) => ({
      ...previous,
      [id]: value,
    }));
  }, []);
  const setMovementCondonedAmountInput = useCallback((id) => (value) => {
    _setMovementCondonedAmountInput((previous) => ({
      ...previous,
      [id]: value,
    }));
  }, []);
  const inputCreator = (movementIds) => movementIds.map((movId) => (
    {
      movementId: movId,
      movementDate: movementDateInput[movId],
      unpayedAmount: movementUnpayedAmountInput[movId],
      condonedAmount: movementCondonedAmountInput[movId],
    }
  ));

  const debitMovements = inputCreator(debitMovementIds);
  const creditMovements = inputCreator(creditMovementIds);

  const onCompletedCallback = useCallback(() => {
    close();
    clearSelection();
  }, []);
  const [conciliate, conciliateData, inputOk] = useConciliate(
    debitMovements,
    creditMovements,
    selectedMovementType,
    conciliationDescription,
    onCompletedCallback,
  );
  const debitMovementsQuery = useGetConciliationMovementByIds(debitMovementIds);
  const creditMovementsQuery = useGetConciliationMovementByIds(creditMovementIds);
  const loading = conciliateData.loading
   || debitMovementsQuery.loading
    || creditMovementsQuery.loading;
  const debitList = debitMovementsQuery.flatMovements;
  const creditList = creditMovementsQuery.flatMovements;
  const debitColumns = ['conciliationMovementAccount_Name', 'description', 'debit'];
  const creditColumns = ['conciliationMovementAccount_Name', 'description', 'credit'];

  const country = useGetCountryFromUrl();
  const availableUnpayedAmount = {
    Mexico: {
      'cxc payment': false,
      'cxc ordering': true,
      'cxc factoring': true,
    },
    Chile: {
      'cxc payment': false,
      'cxc ordering': true,
      'cxc factoring': true,
    },
  };
  const availableCondonatedAmount = {
    Mexico: {
      'cxc payment': true,
      'cxc ordering': true,
      'cxc factoring': true,
    },
    Chile: {
      'cxc payment': true,
      'cxc ordering': true,
      'cxc factoring': true,
    },
  };

  const columns = [
    {
      id: 'movementDate',
      field: 'movementDate',
      headerName: 'Fecha de conciliación',
      type: 'string',
      sortable: false,
      filterable: false,
      width: 150,
      renderEditCell: (params) => (
        <GridEditDateCell
          {...params}
          onChange={setMovementInput}
          value={movementDateInput[params.row.id] || params.row.movementDate}
        />
      ),
      renderCell: (params) => {
        if (params.row.movementDate) {
          return (
            <Typography
              variant="subtitle1"
            >
              {formatDayMonthYear(params.row.movementDate)}
            </Typography>
          );
        }
        return (
          <GridEditDateCell
            {...params}
            value={movementDateInput[params.row.id] || params.row.movementDate}
            onChange={setMovementInput}
          />
        );
      },
    },
    {
      id: 'unpayedAmount',
      field: 'unpayedAmount',
      headerName: 'Monto no pagado',
      type: 'number',
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        const { code } = params.row.conciliationMovementAccount;

        if (country && availableUnpayedAmount[country.name][code]) {
          return (
            <MoneyInput
              value={movementUnpayedAmountInput[params.row.id] || 0}
              setValue={setMovementUnpayedAmountInput(params.row.id)}
              name={params.row.id}
            />
          );
        }
        return null;
      },
    },
    {
      id: 'condonedAmount',
      field: 'condonedAmount',
      headerName: 'Monto a condonar',
      type: 'number',
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        const { code } = params.row.conciliationMovementAccount;

        if (country && availableCondonatedAmount[country.name][code]) {
          return (
            <MoneyInput
              value={movementCondonedAmountInput[params.row.id] || 0}
              setValue={setMovementCondonedAmountInput(params.row.id)}
              name={params.row.id}
            />
          );
        }
        return null;
      },
    },

  ];
  return (
    <FingoDialog
      open={open}
      handleClose={close}
      dialogActionButton={(
        <LoadingButton
          loading={loading}
          disabled={!inputOk}
          onClick={conciliate}
        >
          Conciliar
        </LoadingButton>
      )}
      fullWidth
      maxWidth="100%"
      title="Confirmar conciliación"
    >
      <Grid container direction="row">
        <ConciliationGrid
          rows={debitList}
          columnList={debitColumns}
          columns={columns}
          loading={loading}
        />
        <ConciliationGrid
          rows={creditList}
          columnList={creditColumns}
          columns={columns}
          loading={loading}
        />
        <Grid container direction="row" justifyContent="space-around" my={2}>
          <Grid item>
            <SelectedAmount selectedMovementIds={debitMovementIds} />
          </Grid>
          <Grid item>
            <SelectedAmount selectedMovementIds={creditMovementIds} />
          </Grid>
        </Grid>
      </Grid>

      <Stack direction="column" spacing={2}>
        <Grid container direction="row">
          <MenuButton
            options={movementTypeOptions}
            selectedOption={selectedMovementType}
            setSelectedOption={setSelectedMovementType}
            placeholder="Tipo de conciliación"
            minWidth="50%"
          />
          <TextField
            name="description"
            label="Descripción"
            value={conciliationDescription}
            onChange={setConciliationDescription}
            sx={{
              minWidth: '50%',
            }}
          />
        </Grid>
        <NonOperativeMovement
          credit={creditMovementsQuery.creditValue}
          debit={debitMovementsQuery.debitValue}
          setDebitMovementsId={setLeftSideSelectedId}
          setCreditMovementsId={setRightSideSelectedId}
          disabled={selectedMovementTypeCode?.code !== 'non_operative_movement'}
        />
      </Stack>
    </FingoDialog>
  );
};

ConfirmConciliation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  debitMovementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  creditMovementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  clearSelection: PropTypes.func.isRequired,
  setLeftSideSelectedId: PropTypes.func.isRequired,
  setRightSideSelectedId: PropTypes.func.isRequired,
};

ConciliationGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columnList: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sortable: PropTypes.bool.isRequired,
    filterable: PropTypes.bool.isRequired,
    width: PropTypes.number,
    renderCell: PropTypes.func.isRequired,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConfirmConciliation;
