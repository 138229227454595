import TourGuide from '@fingo/lib/components/joyRides/TourGuide';
import React from 'react';
import PropTypes from 'prop-types';
import useMovementAccountsOptions from '../../../../../hooks/useMovementAccountsOptions';

const ConciliationTourGuide = ({ isOpen, toggleOpen }) => {
  const accounts = useMovementAccountsOptions();
  const accountNames = accounts.options.map(({ label }) => label).join(', ');
  const steps = [
    {
      title: 'Conciliación',
      content: 'Esta es la pestaña de conciliaciones de Fingo.',
      target: '#conciliation-view',
    },
    {
      title: 'Paneles',
      content: `
      En pasivos/ingresos se encontrarán las cuentas por pagar, los movimientos no operativos que reflejan un egreso de dinero, los ingresos de dineros, entre otros.
      En activos/egresos se encontrarán las cuentas por cobrar, los movimientos no operativos que reflejan un ingreso de dinero, los egresos de dineros, entre otros.
      `,
      target: '#paper-header',
    },
    {
      title: 'Cuentas',
      content: `Puedes filtrar por el movimiento que se quiere conciliar. Aquí pillarás ${accountNames}`,
      target: '#account-filter',
    },
    {
      title: 'Funcionamiento',
      content: `
      Elige los movimientos que se deben conciliar. Si los montos no calzan, en la pestaña de confirmar podrás ingresar un monto impago.
      OJO: SIEMPRE ESTE MONTO IRÁ A EXCEDENTES.
      `,
      target: '#datagrid-box',
    },
  ];
  return (
    <TourGuide
      run={isOpen}
      setRun={toggleOpen}
      steps={steps}
    />
  );
};

ConciliationTourGuide.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default ConciliationTourGuide;
