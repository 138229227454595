import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ChangeCompanySurplusDialog from '../dialogs/ChangeCompanySurplusDialog';

const ChangeCompanySurplusAction = ({ companyId = null }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        id="change-company-surplus-dialog-button"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
        disabled={!companyId}
      >
        Abono|Cargo de excedentes
      </Button>
      {open && companyId && (
        <ChangeCompanySurplusDialog
          open={open}
          onClose={() => setOpen(false)}
          companyId={companyId}
        />
      )}
    </>
  );
};

ChangeCompanySurplusAction.propTypes = {
  companyId: PropTypes.string,
};

ChangeCompanySurplusAction.defaultProps = {
  companyId: null,
};

export default ChangeCompanySurplusAction;
