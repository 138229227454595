import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackBars } from '@fingo/lib/hooks';
import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { CHANGE_COMPANY_SURPLUS } from '../../graphql/company-balances/mutations';

const ChangeCompanySurplusDialog = ({ open, onClose, companyId }) => {
  const { addAlert } = useSnackBars();
  const [changeCompanySurplus] = useMutation(CHANGE_COMPANY_SURPLUS);

  const validationSchema = Yup.object({
    addAmount: Yup.number().nullable().min(0, 'El monto debe ser positivo'),
    subtractAmount: Yup.number()
      .nullable()
      .min(0, 'El monto debe ser positivo'),
  });

  const formik = useFormik({
    initialValues: {
      addAmount: '',
      subtractAmount: '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values, { setSubmitting }) => {
      let amount = 0;
      if (values.addAmount) {
        amount = -parseFloat(values.addAmount);
      } else if (values.subtractAmount) {
        amount = parseFloat(values.subtractAmount);
      }
      if (amount !== 0) {
        changeCompanySurplus({
          variables: { companyId, amount },
          onCompleted: () => {
            setSubmitting(false);
            formik.resetForm();
            onClose();
            addAlert({
              id: 'change-company-surplus-success',
              message:
                'Se levantó una solicitud de soporte para el cambio de excedentes',
              severity: 'success',
              color: 'success',
            });
          },
          onError: ({ message }) => {
            setSubmitting(false);
            addAlert({
              id: 'change-company-surplus-error',
              message,
              severity: 'error',
              color: 'error',
            });
          },
        });
      }
    },
  });

  const handleChange = (field, value) => {
    if (field === 'addAmount') {
      formik.setFieldValue('addAmount', value);
      formik.setFieldValue('subtractAmount', '');
    } else {
      formik.setFieldValue('subtractAmount', value);
      formik.setFieldValue('addAmount', '');
    }
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit} style={{ margin: '16px 0' }}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Abonar/Quitar Excedentes
        </DialogTitle>
        <DialogContent
          sx={{
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            margin="dense"
            id="addAmount"
            name="addAmount"
            label="Agregar excedentes"
            type="number"
            InputProps={{ startAdornment: <span>$</span> }}
            value={formik.values.addAmount}
            onChange={(e) => handleChange('addAmount', e.target.value)}
            error={Boolean(formik.errors.addAmount)}
            helperText={formik.errors.addAmount}
          />
          <TextField
            fullWidth
            margin="dense"
            id="subtractAmount"
            name="subtractAmount"
            label="Quitar excedentes"
            type="number"
            InputProps={{ startAdornment: <span>$</span> }}
            value={formik.values.subtractAmount}
            onChange={(e) => handleChange('subtractAmount', e.target.value)}
            error={Boolean(formik.errors.subtractAmount)}
            helperText={formik.errors.subtractAmount}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={handleClose}
            size="small"
            disabled={formik.isSubmitting}
          >
            Cancelar
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            loading={formik.isSubmitting}
            disabled={
              formik.isSubmitting
              || (!formik.values.addAmount && !formik.values.subtractAmount)
            }
          >
            Confirmar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ChangeCompanySurplusDialog.propTypes = {
  companyId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeCompanySurplusDialog;
