import React from 'react';
import PropTypes from 'prop-types';
import DownloadCessionsSummaryAction from './DownloadCessionsSummaryAction';
import ChangeCompanySurplusAction from './ChangeCompanySurplusAction';

const CompanyBalancesActions = ({ selectedCompanyId = null }) => (
  <>
    <DownloadCessionsSummaryAction companyId={selectedCompanyId} />
    <ChangeCompanySurplusAction companyId={selectedCompanyId} />
  </>
);

CompanyBalancesActions.propTypes = {
  selectedCompanyId: PropTypes.string,
};

CompanyBalancesActions.defaultProps = {
  selectedCompanyId: null,
};

export default CompanyBalancesActions;
