import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DownloadCessionsSummaryDialog from '@fingo/lib/components/dialogs/DownloadCessionsSummaryDialog';
import Button from '@mui/material/Button';

const DownloadCessionsSummaryAction = ({ companyId }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        id="download-cession-summary-dialog-button"
        variant="contained"
        size="small"
        onClick={() => setOpen(true)}
        disabled={!companyId}
      >
        Descargar resúmen de cartera
      </Button>
      {open && companyId && (
        <DownloadCessionsSummaryDialog
          customCompany={companyId}
          open={open}
          onClose={() => setOpen(false)}
          product="FACTORING"
        />
      )}
    </>
  );
};

DownloadCessionsSummaryAction.propTypes = {
  companyId: PropTypes.string,
};

DownloadCessionsSummaryAction.defaultProps = {
  companyId: null,
};

export default DownloadCessionsSummaryAction;
