import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { useGetCountryFromUrl, useGetUser } from '@fingo/lib/hooks';
import { useQuery } from '@apollo/client';
import { transfersTabs } from '../routes';
import { COUNT_TRANSFERS } from '../graphql/queries/transfer';

const Transfers = () => {
  const country = useGetCountryFromUrl();
  const { data } = useQuery(COUNT_TRANSFERS, { variables: { countryId: country?.id } });

  const factoringMasterEntities = data?.factoringTransferMasterEntities.totalCount;
  const purchaseOrders = data?.transferPurchaseOrders.totalCount;
  const invoiceLoansMoneyTransfers = data?.invoiceLoansMoneyTransfers.totalCount;
  const tabs = transfersTabs(useGetUser()).map((tab, idx) => ({
    ...tab,
    quantity: [factoringMasterEntities, purchaseOrders, invoiceLoansMoneyTransfers][idx],
  }));

  return <FingoPaper tabs={tabs} />;
};

export default Transfers;
