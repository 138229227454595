import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import useConciliationMovementsColumns from '../../../../../columns/useConciliationMovementsColumns';
import useTourGuide from '../../../../../hooks/useTourGuide';
import { CONCILIATION_MOVEMENTS } from '../../../graphql/queries/conciliation';
import CreditAccountFilter from './CreditAccountFilter';
import ConciliationTourGuide from './ConciliationTourGuide';
import SelectedAmount from './SelectedAmount';

const CreditConciliationMovements = ({
  customQueryVariables,
  selectionModel,
  setSelecionModel,
  title,
  hideColumns,
  currency,
}) => {
  const [accountSelected, setAccountSelected] = useState();
  const { setBoolean, ConciliationComponent } = useTourGuide(ConciliationTourGuide);
  const [file, setFile] = useState();
  const country = useGetCountryFromUrl();
  return (
    <>
      <FingoMainView
        id="conciliation-view"
        query={CONCILIATION_MOVEMENTS}
        queryCustomVariables={{
          ...customQueryVariables,
          conciliationMovementAccount_Id_In: accountSelected ? [accountSelected] : null,
          filterInvoices: file,
          conciliated: false,
          countryId: country?.id,
          currency,
        }}
        useUrlFilters={false}
        slots={{
          header: PaperHeader,
          table: FingoDataGrid,
          actions: CreditAccountFilter,
        }}
        slotProps={{
          header: {
            viewTitle: title,
            finder: {
              searchPlaceHolder: 'Buscar',
            },
            openJoyride: setBoolean,
          },
          table: {
            columns: useConciliationMovementsColumns().filter(
              (mov) => !hideColumns.includes(mov.id),
            ),
            noRowsMessage: () => <Typography>No hay movimientos para conciliar</Typography>,
            initialOrderBy: 'description',
            checkboxSelection: true,
            selectionModel,
            onSelectionModelChange: setSelecionModel,
            keepNonExistentRowsSelected: true,
            rowsPerPageOptions: [10, 25, 50, 100],
          },
          actions: {
            selectedOption: accountSelected,
            setSelectedOption: setAccountSelected,
            setFile,
            file,
          },
        }}
      />
      {ConciliationComponent}
      <SelectedAmount
        selectedMovementIds={selectionModel}
      />
    </>
  );
};

CreditConciliationMovements.propTypes = {
  customQueryVariables: PropTypes.shape().isRequired,
  selectionModel: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelecionModel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  currency: PropTypes.string.isRequired,
};

export default CreditConciliationMovements;
