import { DocumentList } from '@fingo/lib/components/lists';
import { CONFIRMINGS_QUERY } from '@fingo/lib/graphql';
import React, { useCallback, useState } from 'react';
import { MOBILE_HEADERS, useInvoicePreColumns } from '@fingo/lib/constants';
import { CONFIRMING_CUSTOM_COLUMNS, CONFIRMING_COLUMNS } from '../constants/treasury';
import TransfersSummary from './transfers/TransfersSummary';

const ConfirmingTransfersList = () => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const cleanDocuments = useCallback(() => {
    setSelectedDocumentIds([]);
  }, []);

  return (
    <DocumentList
      trackerId="Transfers-Confirming"
      type="confirming-transfer-panel"
      headerTitle="Confirmings por girar"
      queryDocument={CONFIRMINGS_QUERY}
      includeHeaders={CONFIRMING_COLUMNS}
      mobileHeaders={MOBILE_HEADERS}
      showExportInvoice
      preColumns={useInvoicePreColumns(CONFIRMING_CUSTOM_COLUMNS)}
      onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
      flexEndButtons={() => (
        <TransfersSummary
          selectedDocumentIds={selectedDocumentIds}
          cleanDocuments={cleanDocuments}
          modelLabel="confirming"
        />
      )}
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showRefresh: true,
        showCompanyIssuerFilter: false,
      }}
      onCompletedSetRows={
        (data) => data.invoices.edges.map((edge) => (edge.node.relatedConfirming))
      }
      showFilters
      selectionModel={selectedDocumentIds}
      checkboxSelection
      customVariables={{
        allIssuedCompany: false,
        receiverId: null,
        companyId: null,
        receiver_Rut: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        relatedConfirming_InvoiceMoneyTransfer_Status: 'Waiting',
      }}
      initialOrderBy="company_MasterEntity_Name"
    />
  );
};

export default ConfirmingTransfersList;
